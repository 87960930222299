import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import Vant from "vant";
import store from "./store";
import "vant/lib/index.less";
import "normalize.css";
import "./theme/common.less";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(require("vue-wechat-title"));
Vue.use(ElementUI);
// 引入微信SDK
import wx from "weixin-js-sdk";
Vue.prototype.$wx = wx;
console.log("当前环境：" + process.env.NODE_ENV);
Vue.use(Vant);

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/swiper-bundle.min.css";
Vue.use(VueAwesomeSwiper);

import Swiper2, { Navigation, Pagination, EffectCoverflow, Autoplay } from "swiper";
Swiper2.use([Navigation, Pagination, EffectCoverflow, Autoplay]);

import Vconsole from "vconsole";
if (process.env.NODE_ENV !== "production") {
  let dConsole = new Vconsole();
  Vue.use(dConsole);
}

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.prototype.$splitAmount = function (val) {
  if (val === null || val === "") {
    return "-";
  }

  if (typeof val === "number" || typeof val === "string") {
    return Number(val)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  }

  return val;
};
